import { useStaticQuery, graphql } from "gatsby";
import { navigate } from "gatsby-link";
import React, { useEffect, useState } from "react";
import HtmlDiv from "sites-common/components/HtmlDiv";
import PropTypes from "prop-types";

import { Button } from "theme-ui";
// import { Button } from "theme-ui";

const landingPage = require("../../data/landing.json");
const { wpPages } = require("../../data/wp-pages.json");

const goHome = () => navigate("/");

const P404 = ({ location }) => {
  const { pathname, href, protocol, host } = location;
  const [message, setMessage] = useState("loading...");
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            donationClassicWpUrl
          }
        }
      }
    `
  );
  const { donationClassicWpUrl } = site.siteMetadata;

  useEffect(() => {
    let matches = "";
    const wpMatches = wpPages.filter(
      (r) =>
        r.path.trim().replace(/\/$/, "") === pathname.trim().replace(/\/$/, "")
    );
    const localMatches = Object.keys(landingPage)
      .map((r) =>
        landingPage[r].find(
          (f) =>
            f.path.trim().replace(/\/$/, "") ===
            pathname.trim().replace(/\/$/, "")
        )
      )
      .filter((s) => s !== undefined);

    matches =
      localMatches.length && localMatches[0]["local-redirect"]
        ? localMatches
        : wpMatches;

    if (matches.length > 0) {
      const r = matches[0];
      if (r["local-redirect"]) {
        navigate(r["local-redirect"]);
        return;
      }
      if (r.redirect) {
        const redirectUrl = href.replace(
          `${protocol}//${host}`,
          donationClassicWpUrl
        );
        // redirect on success
        setMessage(
          `This page is moved to <a href="${redirectUrl}">${redirectUrl}</a>. Redirecting ...`
        );
        navigate(redirectUrl);
        return;
      }
    }

    setMessage("The page you are looking for is not found on this server");
  }, [pathname, href, protocol, host, donationClassicWpUrl]);

  return (
    <div key={href} style={{ padding: "10px" }}>
      <div>
        <h4>
          <HtmlDiv htmlString={message} />
        </h4>

        <div>
          <br />
          <br />
          <br />

          <Button
            style={{ background: "orange", color: "white", marginTop: "20px" }}
            onClick={goHome}
          >
            Back to Home Page.
          </Button>
        </div>
      </div>
    </div>
  );
};

P404.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired,
    protocol: PropTypes.string.isRequired,
  }).isRequired,
};

export default P404;
